<div class="copilot-summary-block">
  <!-- Loader -->
  <dm-loading
    loader
    *ngIf="isComponentLoading && !isServerError"
    [loadingText]="loadingText"
    [showLoading]="showLoading">
  </dm-loading>

  <!-- Header -->
  <div class="heading" *ngIf="!isComponentLoading && !isServerError">
    <div class="title">
      <div class="copilot-icon" [innerHTML]="copilotIcon"></div>
      <h2>Copilot Summary</h2>
    </div>
    <div class="separator"></div>
    <p class="summary-message">{{ summaryMessage }}</p>
  </div>

  <!-- Carousel Section -->
  <div class="carousel-container" *ngIf="!isComponentLoading && !isServerError">
    <div class="carousel">
      <div
        class="card"
        *ngFor="let project of projects; let i = index"
        [ngStyle]="{'background': cardGradient}"
      >
        <div class="risk-container">
          <h3 class="risk">{{ project.risk }}</h3>
          <div class="trend-icon">
            <div
              *ngIf="project.arrowType === SvgIconNames.ArrowTrendingRed"
              [innerHTML]="trendUpIcon"
            ></div>
            <div
              *ngIf="project.arrowType === SvgIconNames.ArrowTrendingDownGreen"
              [innerHTML]="trendDownIcon"
            ></div>
          </div>
        </div>
        <p class="project-name">{{ project.name }}</p>
        <p class="message">{{ project.message }}</p>
        <button class="btn-go" (click)="goToProject(project.link)">
          <div class="sparkle-icon" [innerHTML]="sparkleIcon"></div>
          Go to project
        </button>
      </div>
    </div>

    <!-- Navigation Arrows and Dots -->
    <div class="carousel-navigation" *ngIf="projects.length > 0">
      <!-- Left Arrow -->
      <div
        class="nav-arrow left"
        (click)="scrollLeft()"
        [class.disabled]="activeStep === 0"
      ></div>
    
      <!-- Dots -->
      <div class="dots">
        <div
          class="dot"
          *ngFor="let step of [].constructor(navSteps + 1); let i = index"
          [class.active]="i === activeStep"
        ></div>
      </div>
    
      <!-- Right Arrow -->
      <div
        class="nav-arrow right"
        (click)="scrollRight()"
        [class.disabled]="activeStep === navSteps"
      ></div>
    </div>    
  </div>
</div>
