import { Component, OnInit, HostListener, Inject, Output, EventEmitter } from "@angular/core";
import { ProjectServiceV2 } from "../../../../common/services/project.v2.service";
import { SvgService } from "../../../../common/services/svg.service";
import { Components, SvgIconNames, RiskBuckets, SourceConstants, LogEventConstants } from "../../../../common/application.constants";
import { SafeHtml } from "@angular/platform-browser";
import { StateService } from "@uirouter/angular";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { Store } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { IState } from "../../../../store/reducers";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmError } from "../../../../common/error.constants";
import {
    ICostOverrunData,
    IProjectDetailsV2
} from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { forkJoin, from, throwError, of } from "rxjs";
import { catchError, filter, take } from "rxjs/operators";

@Component({
    selector: "dm-copilot-summary",
    templateUrl: "./copilot-summary.html",
    styleUrls: ["./copilot-summary.scss"]
})
export class CopilotSummaryComponent extends DmComponentAbstract implements OnInit {
    @Output() public showCopilotSummary = new EventEmitter<boolean>();

    public summaryMessage: string = "";
    public projects: any[] = [];
    public visibleCards: number = 0;
    public navSteps: number = 0;
    public activeStep: number = 0;

    public SvgIconNames = SvgIconNames;
    public copilotIcon!: SafeHtml;
    public sparkleIcon!: SafeHtml;
    public trendUpIcon!: SafeHtml;
    public trendDownIcon!: SafeHtml;

    public isServerError: boolean = false;
    public loadingText: string = "Copilot Summary";

    private readonly cardWidth: number = 382;
    private readonly cardGap: number = 16;
    private readonly statusChangeThresholdDays: number = 7;
    private scrollingInProgress: boolean = false;

    public constructor(
    @Inject(DMLoggerService) dmLogger: DMLoggerService,
        private projectServiceV2: ProjectServiceV2,
        private svgService: SvgService,
        private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(dmLogger, Components.EntitySummaryDetails);
    }

    public ngOnInit(): void {
        this.startLoader();
        const selectedEngagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);

        const engagementDetails$ = this.store
            .select(getEntireEngagementDetails(selectedEngagementId))
            .pipe(
                filter((engagement: IEngagementDetailsState) =>
                    engagement && engagement.loaded && !!engagement.engagementDetails
                ),
                take(1)
            );

        const costOverrun$ = from(
            this.projectServiceV2.getCostOverrunProbabilities(selectedEngagementId)
        ).pipe(
            catchError((error) => {
                // If 404, treat it as "no data" by returning null
                if (error.status === DmError.HttpConstants.NOT_FOUND) {
                    return of(null);
                }
                // For other errors, rethrow
                this.dmLogger.logError(
                    SourceConstants.Component.CopilotSummaryComponent,
                    SourceConstants.Method.NgOnInit,
                    error,
                    "Error fetching cost overrun data"
                );
                return throwError(error);
            })
        );

        forkJoin([engagementDetails$, costOverrun$])
            .pipe(untilDestroyed(this))
            .subscribe(
                ([engagementState, costOverrunResponse]) => {
                    if (!engagementState) {
                        // If somehow engagement details are still not available
                        this.isServerError = true;
                        this.showLoading = false;
                        this.endLoader();
                        this.showCopilotSummary.emit(false);
                        return;
                    }

                    const engagementProjects = engagementState.engagementDetails.projects.filter(
                        (project: IProjectDetailsV2) => !this.sharedFunctionsService.isProjectReadOnly(project)
                    );

                    if (costOverrunResponse === null) {
                        // 404 case: no cost overrun data
                        this.summaryMessage = "No summary data available.";
                        this.projects = [];
                        this.showCopilotSummary.emit(false);
                    } else {
                        // Process cost overrun data
                        this.projects = this.processApiResponse(costOverrunResponse.data, engagementProjects);

                        // Update the summary message
                        const highRiskProjects = this.projects.filter(
                            (project) =>
                                project.risk &&
                                (project.risk.includes(RiskBuckets.HIGH) ||
                                    project.risk.includes(RiskBuckets.VERY_HIGH))
                        ).length;

                        if (highRiskProjects > 0) {
                            this.summaryMessage = `${highRiskProjects} out of ${engagementProjects.length} projects are at risk of exceeding the planned cost!`;
                            this.showCopilotSummary.emit(true);
                        } else {
                            this.projects = [];
                            this.summaryMessage = "";
                            this.showCopilotSummary.emit(false);
                        }

                        this.dmLogger.logEvent(
                            SourceConstants.Component.CopilotSummaryComponent,
                            SourceConstants.Method.NgOnInit ,
                            LogEventConstants.CopilotSummaryLoaded,
                            JSON.stringify({
                                TotalProjects: engagementProjects.length,
                                RiskProjects: highRiskProjects,
                                ProjectDetails: this.projects.map((project) => ({
                                    ProjectId: project.projectId,
                                    Name: project.name,
                                    RiskLevel: project.risk,
                                    OldRiskLevel: project.oldRiskLevel,
                                    IsStatusChange: project.isStatusChange
                                }))
                            })
                        );
                    }

                    this.endLoader();

                    // Recalculate after data is in place and DOM is updated
                    setTimeout(() => {
                        this.calculateVisibleCards();
                    }, 100);
                },
                () => {
                    this.isServerError = true;
                    this.showLoading = false;
                    this.endLoader();
                    this.showCopilotSummary.emit(false);
                }
            );

        // Load SVG icons
        this.copilotIcon = this.svgService.getIcon(SvgIconNames.Copilot);
        this.sparkleIcon = this.svgService.getIcon(SvgIconNames.Sparkle);
        this.trendUpIcon = this.svgService.getIcon(SvgIconNames.ArrowTrendingRed);
        this.trendDownIcon = this.svgService.getIcon(SvgIconNames.ArrowTrendingDownGreen);
    }

    @HostListener("window:resize")
    public onResize(): void {
        this.activeStep = 0;
        const container = document.querySelector(".carousel") as HTMLElement;
        if (container) {
            container.scrollLeft = 0;
        }
        this.calculateVisibleCards();
    }

    public scrollLeft(): void {
        if (this.scrollingInProgress) {return; }

        this.scrollingInProgress = true;
        const container = document.querySelector(".carousel") as HTMLElement;

        if (container) {
            container.scrollBy({
                left: -(this.cardWidth + this.cardGap) * this.visibleCards,
                behavior: "smooth"
            });

            setTimeout(() => {
                this.scrollingInProgress = false;
            }, 400);

            if (this.activeStep > 0) {
                this.activeStep--;
            }

            this.dmLogger.logEvent(
                SourceConstants.Component.CopilotSummaryComponent,
                SourceConstants.Method.ScrollLeft,
                LogEventConstants.ScrolledLeft,
                JSON.stringify({ ActiveStep: this.activeStep })
            );
        } else {
            this.scrollingInProgress = false;
            this.dmLogger.logError(
                SourceConstants.Component.CopilotSummaryComponent,
                SourceConstants.Method.ScrollLeft,
                "Carousel container not found",
                "DOM Element Missing"
            );
        }
    }

    public scrollRight(): void {
        if (this.scrollingInProgress) {return; }

        this.scrollingInProgress = true;
        const container = document.querySelector(".carousel") as HTMLElement;

        if (container) {
            container.scrollBy({
                left: (this.cardWidth + this.cardGap) * this.visibleCards,
                behavior: "smooth"
            });

            setTimeout(() => {
                this.scrollingInProgress = false;
            }, 400);

            if (this.activeStep < this.navSteps) {
                this.activeStep++;
            }
            this.dmLogger.logEvent(
                SourceConstants.Component.CopilotSummaryComponent,
                SourceConstants.Method.ScrollRight,
                LogEventConstants.ScrolledRight,
                { ActiveStep: this.activeStep }
            );
        } else {
            this.scrollingInProgress = false;
            this.dmLogger.logError(
                SourceConstants.Component.CopilotSummaryComponent,
                SourceConstants.Method.ScrollRight,
                "Carousel container not found",
                "DOM Element Missing"
            );
        }
    }

    public goToProject(projectId: string): void {
        const selectedProject = this.projects.find((project) => project.projectId === projectId);
        if (!selectedProject) {return; }

        if (selectedProject) {
            this.dmLogger.logUserAction(
                SourceConstants.Component.CopilotSummaryComponent,
                SourceConstants.Method.GoToProject,
                LogEventConstants.GoToProjectClicked,
                "User clicked project card",
                JSON.stringify({
                    ProjectId: projectId,
                    RiskLevel: selectedProject.risk,
                    IsStatusChange: selectedProject.isStatusChange,
                    OldRiskLevel: selectedProject.oldRiskLevel
                })
            );
        }

        window.location.href = `#/pjm/project/${projectId}/forecast`;
    }

    public onCardHover(projectId: string): void {
        const selectedProject = this.projects.find((project) => project.projectId === projectId);

        if (!selectedProject) {return; }

        this.dmLogger.logEvent(
            SourceConstants.Component.CopilotSummaryComponent,
            SourceConstants.Method.OnCardHover,
            "User hovered over project card",
            JSON.stringify({
                ProjectId: projectId,
                RiskLevel: selectedProject.risk,
                IsStatusChange: selectedProject.isStatusChange,
                OldRiskLevel: selectedProject.oldRiskLevel
            }),
            undefined,
        );
    }

    public onHoverCopilotSummary(): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.CopilotSummaryComponent,
            SourceConstants.Method.OnHoverCopilotSummary,
            "User hovered over Copilot Summary section",
        );
    }

    private calculateVisibleCards(): void {
        const carouselElement = document.querySelector(".carousel") as HTMLElement;
        if (!carouselElement) {return; }

        const containerWidth = carouselElement.clientWidth;
        this.visibleCards = Math.floor(containerWidth / (this.cardWidth + this.cardGap));

        this.calculateNavSteps();
    }

    private calculateNavSteps(): void {
        if (this.visibleCards > 0) {
            this.navSteps = Math.max(0, Math.ceil(this.projects.length / this.visibleCards) - 1);
            this.activeStep = Math.min(this.activeStep, this.navSteps);
        } else {
            this.navSteps = 0;
            this.activeStep = 0;
        }
    }

    private processApiResponse(apiData: ICostOverrunData[], engagementProjects: IProjectDetailsV2[]): any[] {
        const projectMap: any = {};

        apiData.forEach((entry) => {
            const projectId = entry.projectID;
            if (!projectMap[projectId]) {
                projectMap[projectId] = [];
            }
            projectMap[projectId].push(entry);
        });

        const statusChangeCards = [];
        const nonStatusChangeCards = [];
        const today = new Date();

        for (const projectId in projectMap) {
            const projectLogs = projectMap[projectId];
            projectLogs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

            const latestLog = projectLogs[0];
            const previousLog = projectLogs.length > 1 ? projectLogs[1] : null;
            const project = engagementProjects.find((p) => p.id === projectId);
            if (!project) {
                continue;
            }

            const bucket = latestLog.bucket;
            const predictedProbability = latestLog.predictedProbability;
            let riskLevel = bucket + " Risk";
            let message = "";

            // Determine the trend arrow based on risk changes
            let arrowType = SvgIconNames.ArrowTrendingRed;
            if (
                previousLog &&
                ((previousLog.bucket === RiskBuckets.VERY_HIGH &&
                    (bucket === RiskBuckets.HIGH ||
                        bucket === RiskBuckets.MEDIUM ||
                        bucket === RiskBuckets.LOW)) ||
                    (previousLog.bucket === RiskBuckets.HIGH &&
                        (bucket === RiskBuckets.MEDIUM || bucket === RiskBuckets.LOW)) ||
                    (previousLog.bucket === RiskBuckets.MEDIUM && bucket === RiskBuckets.LOW))
            ) {
                const previousDate = new Date(previousLog.date);
                const diffInDays = Math.floor((today.getTime() - previousDate.getTime()) / (1000 * 60 * 60 * 24));

                if (diffInDays <= this.statusChangeThresholdDays) {
                    arrowType = SvgIconNames.ArrowTrendingDownGreen;
                }
            }

            // ---------------------------------------
            // Case 1: Single entry (no previousLog)
            // ---------------------------------------
            if (projectLogs.length === 1) {
                if (bucket === RiskBuckets.HIGH || bucket === RiskBuckets.VERY_HIGH) {
                    message = `${bucket} risk of exceeding the planned cost detected!`;
                    nonStatusChangeCards.push({
                        risk: riskLevel,
                        name: project.name,
                        message,
                        projectId,
                        arrowType,
                        predictedProbability,
                        isStatusChange: false,
                        oldRiskLevel: null,
                    });
                }
            }
            // ---------------------------------------
            // Case 2: Exactly two entries => possible status change
            // ---------------------------------------
            else if (projectLogs.length === 2 && previousLog) {
                const previousDate = new Date(previousLog.date);
                const diffInDays = Math.floor((today.getTime() - previousDate.getTime()) / (1000 * 60 * 60 * 24));

                // Ignore status change from Medium <-> Low
                const relevantChange =
                    previousLog.bucket === RiskBuckets.HIGH ||
                    previousLog.bucket === RiskBuckets.VERY_HIGH ||
                    bucket === RiskBuckets.HIGH ||
                    bucket === RiskBuckets.VERY_HIGH;

                if (!relevantChange) {
                    continue;
                }

                // Case 2.2: Status change within a week
                if (diffInDays <= this.statusChangeThresholdDays) {
                    riskLevel = "Status Change - " + riskLevel;

                    message = `Status has been updated from "${previousLog.bucket}" to "${bucket}" cost overrun.`;

                    statusChangeCards.push({
                        risk: riskLevel,
                        name: project.name,
                        message,
                        projectId,
                        arrowType,
                        predictedProbability,
                        isStatusChange: true,
                        oldRiskLevel: previousLog.bucket
                    });
                } else {
                    // Case 2.1: Status change older than a week => revert to single-entry behavior
                    if (bucket === RiskBuckets.HIGH || bucket === RiskBuckets.VERY_HIGH) {
                        message = `${bucket} risk of exceeding the planned cost detected!`;
                        nonStatusChangeCards.push({
                            risk: riskLevel,
                            name: project.name,
                            message,
                            projectId,
                            arrowType,
                            predictedProbability,
                            isStatusChange: false,
                            oldRiskLevel: null
                        });
                    }
                }
            }
        }

        // Sort: Status change cards first (by predicted probability), then non-status change cards by predicted probability
        statusChangeCards.sort((a, b) => b.predictedProbability - a.predictedProbability);
        nonStatusChangeCards.sort((a, b) => b.predictedProbability - a.predictedProbability);

        return [...statusChangeCards, ...nonStatusChangeCards];
    }
}
