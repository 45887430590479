import { Component, OnInit, HostListener, Inject } from "@angular/core";
import { ProjectServiceV2 } from "../../../../common/services/project.v2.service";
import { SvgService } from "../../../../common/services/svg.service";
import { Components, SvgIconNames, RouteName, RiskBuckets } from "../../../../common/application.constants";
import { SafeHtml } from "@angular/platform-browser";
import { StateService } from "@uirouter/angular";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { Store } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { IState } from "../../../../store/reducers";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmError } from "../../../../common/error.constants";
import {
    ICostOverrunData,
    IProjectDetailsV2,
} from "../../../../common/services/contracts/wbs-details-v2.contracts";

@Component({
    selector: "dm-copilot-summary",
    templateUrl: "./copilot-summary.html",
    styleUrls: ["./copilot-summary.scss"],
})
export class CopilotSummaryComponent extends DmComponentAbstract implements OnInit {
    public summaryMessage: string = "";
    public projects: any[] = [];
    public cardWidth: number = 382;
    public visibleCards: number = 0;
    public navSteps: number = 0;
    public activeStep: number = 0;

    public SvgIconNames = SvgIconNames;
    public copilotIcon!: SafeHtml;
    public sparkleIcon!: SafeHtml;
    public trendUpIcon!: SafeHtml;
    public trendDownIcon!: SafeHtml;

    public isServerError: boolean = false;
    public loadingText: string = "Copilot Summary";

    public constructor(
    @Inject(DMLoggerService) dmLogger: DMLoggerService,
        private projectServiceV2: ProjectServiceV2,
        private svgService: SvgService,
        private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(dmLogger, Components.EntitySummaryDetails);
    }

    public ngOnInit(): void {
        this.startLoader();
        const selectedEngagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(selectedEngagementId));

        engagementDetails$
            .pipe(untilDestroyed(this))
            .subscribe(async (engagementDetails: IEngagementDetailsState) => {
                if (engagementDetails && engagementDetails.loaded && engagementDetails.engagementDetails) {
                    const engagementProjects = engagementDetails.engagementDetails.projects || [];

                    try {
                        // Fetch cost overrun probabilities and process data
                        const response = await this.projectServiceV2.getCostOverrunProbabilities(
                            selectedEngagementId
                        );
                        const projectsData = this.processApiResponse(response.data, engagementProjects);
                        this.projects = projectsData;

                        // Update the summary message
                        const highRiskProjects = this.projects.filter(
                            (project) =>
                                project.risk &&
                                (project.risk.includes(RiskBuckets.HIGH) ||
                                    project.risk.includes(RiskBuckets.VERY_HIGH))
                        ).length;

                        this.summaryMessage = `${highRiskProjects} out of ${engagementProjects.length} projects are at risk of exceeding the planned cost!`;

                        this.endLoader();
                        setTimeout(() => {
                            this.calculateVisibleCards();
                        }, 0);
                    } catch (error) {
                        if (error.status === DmError.HttpConstants.NOT_FOUND) {
                            this.summaryMessage = "No summary data available.";
                        } else {
                            // TODO: Add Logging
                            this.isServerError = true;
                        }
                        this.showLoading = false;
                        this.endLoader();
                    }
                } else if (engagementDetails && engagementDetails.loaded) {
                    // TODO: Add Logging
                    this.isServerError = true;
                    this.showLoading = false;
                    this.endLoader();
                }
            });

        // Load SVG icons
        this.copilotIcon = this.svgService.getIcon(SvgIconNames.Copilot);
        this.sparkleIcon = this.svgService.getIcon(SvgIconNames.Sparkle);
        this.trendUpIcon = this.svgService.getIcon(SvgIconNames.ArrowTrendingRed);
        this.trendDownIcon = this.svgService.getIcon(SvgIconNames.ArrowTrendingDownGreen);
    }

    @HostListener("window:resize", ["$event"])
    public onResize(): void {
        this.calculateVisibleCards();
    }

    public scrollLeft(): void {
        if (this.activeStep > 0) {
            this.activeStep--;
            const container = document.querySelector(".carousel") as HTMLElement;
            if (container) {
                container.scrollBy({
                    left: -this.cardWidth * this.visibleCards,
                    behavior: "smooth",
                });
            }
        }
    }

    public scrollRight(): void {
        if (this.activeStep < this.navSteps) {
            this.activeStep++;
            const container = document.querySelector(".carousel") as HTMLElement;
            if (container) {
                container.scrollBy({
                    left: this.cardWidth * this.visibleCards,
                    behavior: "smooth",
                });
            }
        }
    }

    public goToProject(link: string): void {
        window.open(link, "_blank");
    }

    private calculateVisibleCards(): void {
        const carouselElement = document.querySelector(".carousel") as HTMLElement;
        const containerWidth = carouselElement ? carouselElement.clientWidth : 0;
        this.visibleCards = Math.floor(containerWidth / this.cardWidth);
        this.calculateNavSteps();
    }

    private calculateNavSteps(): void {
        if (this.visibleCards > 0) {
            this.navSteps = Math.ceil(this.projects.length / this.visibleCards) - 1;
            this.activeStep = Math.min(this.activeStep, this.navSteps);
        } else {
            this.navSteps = 0;
            this.activeStep = 0;
        }
    }

    private processApiResponse(apiData: ICostOverrunData[], engagementProjects: IProjectDetailsV2[]): any[] {
        const projectMap: any = {};

        apiData.forEach((entry) => {
            const projectId = entry.projectID;
            if (!projectMap[projectId]) {
                projectMap[projectId] = [];
            }
            projectMap[projectId].push(entry);
        });

        const cards = [];
        const today = new Date();

        for (const projectId in projectMap) {
            const projectLogs = projectMap[projectId];
            projectLogs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

            const latestLog = projectLogs[0];
            const previousLog = projectLogs.length > 1 ? projectLogs[1] : null;
            const project = engagementProjects.find((p) => p.id === projectId);
            if (!project) {
                continue;
            }

            const bucket = latestLog.bucket;
            const predictedProbability = latestLog.predictedProbability;
            let riskLevel = bucket + " Risk";
            let message = "";

            // Determine the trend arrow based on risk changes
            let arrowType = SvgIconNames.ArrowTrendingRed;
            if (
                previousLog &&
                ((previousLog.bucket === RiskBuckets.VERY_HIGH &&
                    (bucket === RiskBuckets.HIGH ||
                        bucket === RiskBuckets.MEDIUM ||
                        bucket === RiskBuckets.LOW)) ||
                    (previousLog.bucket === RiskBuckets.HIGH &&
                        (bucket === RiskBuckets.MEDIUM || bucket === RiskBuckets.LOW)) ||
                    (previousLog.bucket === RiskBuckets.MEDIUM && bucket === RiskBuckets.LOW))
            ) {
                const previousDate = new Date(previousLog.date);
                const diffInDays = Math.floor(
                    (today.getTime() - previousDate.getTime()) / (1000 * 60 * 60 * 24)
                );

                if (diffInDays <= 7) {
                    arrowType = SvgIconNames.ArrowTrendingDownGreen;
                }
            }

            if (projectLogs.length === 1) {
                // Case 1: Single entry
                if (bucket === RiskBuckets.HIGH || bucket === RiskBuckets.VERY_HIGH) {
                    message = `${bucket} risk of exceeding the planned cost detected!`;
                    cards.push({
                        risk: riskLevel,
                        name: project.name,
                        message,
                        link: this.generateProjectLink(projectId),
                        arrowType,
                        predictedProbability,
                    });
                }
            } else if (projectLogs.length === 2 && previousLog) {
                // Case 2: Two entries (previousLog exists)
                const previousBucket = previousLog.bucket;
                const previousDate = new Date(previousLog.date);

                // Check if the previous log is within 7 days of the latest date
                const diffInDays = Math.floor(
                    (today.getTime() - previousDate.getTime()) / (1000 * 60 * 60 * 24)
                );

                if (diffInDays <= 7) {
                    // Case 2.2: Status change within a week
                    riskLevel = "Status Change - " + riskLevel;

                    if (bucket === RiskBuckets.LOW) {
                        message =
                            "Risk Update: likelihood of exceeding the planned cost is low. Stay on track.";
                    } else if (
                        previousBucket === RiskBuckets.VERY_HIGH ||
                        previousBucket === RiskBuckets.HIGH
                    ) {
                        message = `Risk Update: Likelihood of exceeding the planned cost lowered to ${bucket}. Continue monitoring.`;
                    } else {
                        message = `Risk Spike: Likelihood of exceeding the cost elevated to ${bucket}.`;
                    }

                    cards.push({
                        risk: riskLevel,
                        name: project.name,
                        message,
                        link: this.generateProjectLink(projectId),
                        arrowType,
                        predictedProbability,
                    });
                } else {
                    // Case 2.1: Status change older than a week
                    if (bucket === RiskBuckets.HIGH || bucket === RiskBuckets.VERY_HIGH) {
                        message = `${bucket} risk of exceeding the planned cost detected!`;
                        cards.push({
                            risk: riskLevel,
                            name: project.name,
                            message,
                            link: this.generateProjectLink(projectId),
                            arrowType,
                            predictedProbability,
                        });
                    }
                }
            }
        }

        // Sort cards by predictedProbability in descending order
        cards.sort((a, b) => b.predictedProbability - a.predictedProbability);

        return cards;
    }

    private generateProjectLink(projectId: string): string {
        return this.stateService.href(RouteName.ProjectPlanForecast, { projectId });
    }
}
