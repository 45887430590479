import { Injectable, Inject, forwardRef } from "@angular/core";
import { UserInfoService } from "@fxp/fxpservices";

import { APIConstants, RequestedState, Services } from "./../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { IWbsDemand, IWbsIncident, IWbsSettings } from "./contracts/project.service.v2.contracts";
import { IWbsStructure, IProject } from "./contracts/wbsStructures.contracts";
import moment from "moment";
import { INewInternalEngagementProjectInput, INewCreateInternalEngagementOutput } from "../../components/new-internal-engagement/new-internal-engagement.contracts";
import { IProjectManagementKeyLinksViewModel } from "../../components/tiles/key-links/key-links.model";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";
import { IEngagementDetailsApiV2, ContractType, IServiceDetailsV2, IProjectDetailsV2, ICostOverrunResponse } from "./contracts/wbs-details-v2.contracts";

@Injectable()
export class ProjectServiceV2 extends DmServiceAbstract {

    private currentUserBPID: number;
    private projectServiceBaseUriv2: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.ProjectServiceV2);
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
        this.currentUserBPID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
    }

    /**
     * Initialize the project service with various URIs and Subscription Keys
     */
    public initializeConfig(): void {
        this.projectServiceBaseUriv2 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
    }


    /**
     * Gets the demand details for a Given WBS_ID
     * 
     * @param {string} wbsId
     * @param {boolean} includeDetail
     * @param {boolean} includeWeeklySchedule
     * @returns {Promise<IWbsDemand>}
     * @memberof ProjectService
     */
    public getWbsDemands(wbsId: string, includeDetails: boolean = true, includeWeeklySchedules: boolean = true): Promise<IWbsDemand> {
        this.initializeConfig();
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/demands?includeDetails=${includeDetails}&includeWeeklySchedules=${includeWeeklySchedules}`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.GetDemandByWbsID, []);
    }

    /**
     * Gets the Demand details for Delivery Baseline
     * 
     * @param {string} wbsId
     * @param {boolean} includeDetail
     * @param {boolean} includeWeeklySchedule
     * @returns {Promise<IWbsDemand>}
     * @memberof ProjectService
     */
    public getDeliveryBaselineWbsDemands(wbsId: string, includeDetails: boolean = true): Promise<IWbsDemand> {
        this.initializeConfig();
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/demands?includeDetails=${includeDetails}&includeWeeklySchedules=false&isDeliveryBaseline=true`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.GetDemandByWbsID, []);
    }

    /**
     * Gets the WBS Structure details for a given WBS_ID
     * Will return IWbsStructure if you pass an engagement ID, but will return IProject if you pass a project ID
     * 
     * @param {string} wbsId
     * @param {boolean} includeHierarchy
     * @param {boolean} includeTeams
     * @returns {Promise<IWbsStructure | IProject>}
     * @memberof ProjectService
     */
    public getWbsStructures(wbsId: string, includeHierarchy: boolean, includeTeams: boolean): Promise<IWbsStructure | IProject> {
        this.initializeConfig();
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/structuredetails?includeHierarchy=${includeHierarchy}&includeTeams=${includeTeams}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetWbsStructuresByWbsId)
            .then((response: IWbsStructure | IProject) => {
                if (response && (response as IWbsStructure).projects) {
                    for (const project of (response as IWbsStructure).projects) {
                        project.daysDiffProjectStartCurrentDate = (moment(project.startDate).diff(moment(new Date()), "days") < 0) ? (moment(new Date()).diff(moment(project.startDate), "days"))
                            : (moment(project.startDate).diff(moment(new Date()), "days"));
                        project.isAssigned = (project.teamStructure.filter((entity) => Number(entity.bpid) === this.currentUserBPID).length > 0);
                    }
                }
                return response;
            });
    }

    /**
     * Adds a project to an existing internal engagement given the engagement
     * ID and project details.
     *
     * @param {string} engagementId
     * @param {INewInternalEngagementProjectInput} projectDetails
     * @returns {Promise<any>}
     * @memberof ProjectServiceV2
     */
    public addProjectToExistingInternalEngagement(engagementId: string, projectDetails: INewInternalEngagementProjectInput): Promise<INewCreateInternalEngagementOutput> {
        this.initializeConfig();
        const url = `${this.projectServiceBaseUriv2}/internal/engagement/${engagementId}/project`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.AddProjectToExistingInternalEngagement, projectDetails);
    }

    /**
     * Calls the PMS API to get the list of NBUE document links for the projects within the given internal engagement ID.
     * Will only work for internal engagement IDs.
     * Company code is mandatory and will throw a 400 if not included, despite what other documents may say.
     * This API does not rely on SAP and can be handled independently.
     * Will return the Internal Project ID as the name, not the project name.
     *
     * @param {string} internalEngagementId
     * @param {string} companyCode
     * @returns {Promise<IProjectManagementKeyLinksViewModel[]>}
     * @memberof ProjectServiceV2
     */
    public getInternalEngagementNBUEDocLinks(internalEngagementId: string, companyCode: string): Promise<IProjectManagementKeyLinksViewModel[]> {
        const url = `${this.projectServiceBaseUriv2}/internal/engagement/${internalEngagementId}/documents?companyCode=${companyCode}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetInternalEngNBUEApprovalDocLinks);
    }

    /**
     * Get Settings for a given wbsId like custom links, ebs state requested, if is submitted for manual activation
     */
    public getWbsSettings(wbsId: string): Promise<IWbsSettings> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/settings`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetWbsSettings);
    }

    /**
     * Get Settings for a given wbsId like custom links, ebs state requested, if is submitted for manual activation
     */
    public getActiveWbsIncidents(wbsId: string): Promise<IWbsIncident[]> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/incidents/active`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetActiveWbsIncidents);
    }

    /**
     * Get default wbs settings , in case wbs does not exist in cosmos
     */
    public getDefaultWbsSettings(wbsId: string): IWbsSettings {
        const wbsSettings: IWbsSettings = {
            wbsId,
            requestedState: RequestedState.NoStateChangeRequested,
            isSubmittedForManualActivation: false,
            customLinks: []
        };
        return wbsSettings;
    }

    /**
     * Save Settings for a given wbsId like custom links, ebs state requested, if is submitted for manual activation
     */
    public saveWbsSettings(wbsSettings: IWbsSettings): Promise<IWbsSettings> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsSettings.wbsId}/settings`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.SaveWbsSettings, wbsSettings);
    }

    /**
     * Extracts project Name, service Name, fixed feee flag from expense task id. 
     *
     * @public
     * @param {string} expenseTaskId
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @returns
     * @memberof FinancialChangeRequestComponent
     */
    public extractWbsDetailsFromTaskId(expenseTaskId: string, engagementDetails: IEngagementDetailsApiV2): {
        projectName: string;
        serviceName: string;
        isFixedFeeProject: boolean;
        projectId: string;
    } {
        let isFixedFeeProject: boolean = false;
        let projectName: string = "";
        let serviceName: string = "";
        let projectId: string = "";
        let projectInfo: IProjectDetailsV2;
        let serviceInfo: IServiceDetailsV2;

        for (const project of engagementDetails.projects) {
            for (const service of project.services) {
                for (const task of service.tasks) {
                    if (task.id === expenseTaskId) {
                        projectInfo = project;
                        serviceInfo = service;
                    }
                }
            }
        }

        if (projectInfo) {
            isFixedFeeProject = projectInfo.contractType === ContractType.FixedFee;
            projectName = projectInfo.name;
            projectId = projectInfo.id;
            if (serviceInfo) {
                serviceName = serviceInfo.name;
            }
        }

        return {
            projectName,
            isFixedFeeProject,
            serviceName,
            projectId
        };
    }

    /**
     * Fetches cost overrun probabilities for a project or engagement.
     * @param wbsId The ID of the project or engagement.
     */
    public getCostOverrunProbabilities(wbsId: string): Promise<ICostOverrunResponse> {
        const suppressErrorCode = 403;
        const url = `${this.projectServiceBaseUriv2}/costoverrun/wbs/${wbsId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetCostOverrunProbabilities, [suppressErrorCode]);
    }
}
