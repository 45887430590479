import { Inject, Injectable } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { SvgIconNames, SVG_ICONS, Services } from "../application.constants";
import { ISvgService } from "./contracts/svg.service.contracts";
import { DMLoggerService } from "./dmlogger.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";

@Injectable()
export class SvgService extends DmServiceAbstract implements ISvgService {
    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService, private sanitizer: DomSanitizer) {
        super(dmLogger, Services.ResourceStaffingService);
    }

    /**
     * Retrieve and sanitize an SVG icon by name.
     * @param name The name of the SVG icon.
     * @returns A sanitized SVG string as SafeHtml or the default icon if not found.
     */
    public getIcon(name: SvgIconNames): SafeHtml {
        const svgString = SVG_ICONS[name] || SVG_ICONS[SvgIconNames.Default];
        return this.sanitizeSvg(svgString);
    }

    /**
     * List all available SVG icon names.
     * @returns Array of SVG icon names.
     */
    public listIcons(): SvgIconNames[] {
        return Object.keys(SVG_ICONS) as SvgIconNames[];
    }

    /**
     * Sanitizes an SVG string for safe rendering.
     * @param svgString The raw SVG string.
     * @returns A SafeHtml object that can be safely rendered in the DOM.
     */
    private sanitizeSvg(svgString: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(svgString);
    }
}
