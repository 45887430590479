<section class="dm-p-x-12-y-imp-0">
    <section class="row">
        <!-- Left Section -->
        <section class="col-lg-4 col-md-4 col-sm-4 col-xs-12 dm-p-r-imp-0">
            <section class="dm-m-b-12">
                <dm-entity-summary-details></dm-entity-summary-details>
            </section>
            <section class="dm-m-b-12">
                <dm-contacts-v2></dm-contacts-v2>
            </section>
            <section class="dm-m-b-12">
                <dm-invoice-summary></dm-invoice-summary>
            </section>
            <section class="dm-m-b-40">
                <dm-key-links-v2></dm-key-links-v2>
            </section>
        </section>

        <!-- Right Section -->
        <section class="col-lg-8 col-md-8 col-sm-8 col-xs-12 dm-p-l-imp-8">
            <section class="dm-m-b-12" *ngIf="shouldDisplayCopilotSummary">
                <dm-copilot-summary (showCopilotSummary)="onShowCopilotSummary($event)"></dm-copilot-summary>
            </section>
            <section class="dm-m-b-12">
                <dm-engagement-summary-project-v2></dm-engagement-summary-project-v2>
            </section>
            <section class="dm-m-b-12">
                <dm-engagement-summary-financials-v2></dm-engagement-summary-financials-v2>
            </section>
            <section class="dm-m-b-12">
                <dm-summary-financials-breakdown></dm-summary-financials-breakdown>
            </section>
            <section class="dm-m-b-40">
                <dm-key-actions-v2 *ngIf="listOfKeyActions.length > 0" [listOfActions]="listOfKeyActions">
                </dm-key-actions-v2>
            </section>
            <section class="dm-m-b-40">
                <dm-purchase-orders-v2></dm-purchase-orders-v2>
            </section>
        </section>
    </section>
</section>
