import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { EngagementSummaryV2Component } from "./engagement-summary-v2.component";
import { EntitySummaryDetailsModule } from "./tiles/entity-summary-details/entity-summary-details.module";
import { EngagementSummaryFinancialsV2Module } from "./tiles/engagement-summary-financials-v2/engagement-summary-financials-v2.module";
import { DmContactsTileV2Module } from "../tiles/dm-contacts-tile-v2/dm-contacts-tile-v2.module";
import { InvoiceSummaryModule } from "../invoices/invoice-summary/invoice-summary.module";
import { EngagementSummaryProjectV2Module } from "./tiles/engagement-summary-project-v2/engagement-summary-project-v2.module";
import { PurchaseOrderV2Module } from "../tiles/purchase-orders-v2/purchase-orders-v2.module";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { ProjectService } from "../../common/services/project.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { KeyLinksV2Module } from "../tiles/key-links-v2/key-links-v2.module";
import { SummaryFinancialsBreakdownModule } from "./tiles/summary-financials-breakdown/summary-financials-breakdown.module";
import { KeyActionsV2Module } from "../tiles/key-actions-v2/key-actions-v2.module";
import { CopilotSummaryModule } from "./tiles/copilot-summary/copilot-summary.module";

@NgModule({
    declarations: [
        EngagementSummaryV2Component
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        EntitySummaryDetailsModule,
        EngagementSummaryFinancialsV2Module,
        DmContactsTileV2Module,
        EngagementSummaryProjectV2Module,
        InvoiceSummaryModule,
        PurchaseOrderV2Module,
        KeyLinksV2Module,
        KeyActionsV2Module,
        SummaryFinancialsBreakdownModule,
        CopilotSummaryModule
    ],
    providers: [
        DMLoggerService,
        ConfigManagerService,
        EngagementDetailService,
        ProjectService,
        SharedFunctionsService,
    ],
    exports: [
        EngagementSummaryV2Component
    ],
    entryComponents: [
        EngagementSummaryV2Component
    ]
})
export class EngagementSummaryV2Module { }
