import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import {
    Component,
    Inject,
} from "@angular/core";
import { RouteName, Components, KeyAction, FeedBackEntity, InlineSurveyDataList, SourceConstants } from "../../common/application.constants";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { IBillingMilestoneModel } from "../../common/services/contracts/dmmilestone.service.contract";
import { IEngagementDetailsState } from "../../store/engagement-details/engagement-details.reducer";
import { IEngagementViewModel } from "../engagement-detail/engagement.model";
import { IState } from "../../store/reducers";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { SortOptions } from "../invoices/invoice-table-data/invoice-table-data.component";
import { untilDestroyed } from "ngx-take-until-destroy";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { getEntireUserPreference } from "../../store/userspreferences/userpreference.selector";
import { IUserPreferenceState } from "../../store/userspreferences/userpreference.reducer";
import { FeedbackModalService } from "../tiles/feedback-modal/feedback-modal.service";
import { ConfigManagerService } from "../../common/services/configmanager.service";

@Component({
    selector: "dm-engagement-summary-v2",
    templateUrl: "./engagement-summary-v2.html",
    styleUrls: ["./engagement-summary-v2.scss"]
})
export class EngagementSummaryV2Component extends DmComponentAbstract {
    public contextTile = "EngagementDetail";
    public enableReadyForActivation: boolean = false;
    public engagementDetails: IEngagementViewModel;
    public invoiceSortVal: SortOptions = SortOptions.Timestamp;
    public limitInvoices: number = 5;
    public milestones: IBillingMilestoneModel[];
    public RouteName = RouteName;
    public isContactsLoading: boolean;
    public listOfKeyActions: string[] = [];
    public isToggleTileSizes: boolean = false; // added for toggling tile sizes for financial breakdown and keylinks for testing puropse. will be removed once tile sizes will be finalized.
    public currentRouteName: string;
    public shouldDisplayCopilotSummary = true;
    private haveMountedComponents: boolean = false;
    private isComplexUnitsFeatureEnabled: boolean = false;

    public constructor(
    @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService)
        private sharedFunctionsService: SharedFunctionsService,
        @Inject(EngagementDetailService)
        private engagementDetailService: EngagementDetailService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(FeedbackModalService) private feedbackModalService: FeedbackModalService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService
    ) {
        super(dmLogger, Components.EngagementSummaryV2, [
            { component: Components.EntitySummaryDetails, isCritical: true },
            { component: Components.EngagementSummaryFinancialsV2, isCritical: true },
            { component: Components.SummaryContactsV2, isCritical: true },
            { component: Components.EngagementSummaryProjectsV2, isCritical: true },
            { component: Components.SummaryInvoicesV2, isCritical: true },
            { component: Components.SummaryPurchaseOrdersV2, isCritical: true },
            { component: Components.SummaryKeyLinksV2, isCritical: true },
            { component: Components.SummaryFinancialsBreakdownComponent, isCritical: true }
        ]);
    }

    /**
     * Lifecycle hook to initialize the component and all info for the view.
     */
    public ngOnInit(): void {
        const selectedId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);

        this.listOfKeyActions.push(KeyAction.EngagementPendingForRelease, KeyAction.PendingExpenseReportsBasedOnEntityId, KeyAction.PendingLaborBasedOnAssignments, KeyAction.PendingMilestonesForConfirmation, KeyAction.EngagementStartDateLessContractStartDate, KeyAction.ActiveMisalignemdContractualAmendments, KeyAction.SubconOnboardingStatus);
        this.isComplexUnitsFeatureEnabled = this.configurationService.isFeatureEnabled("enableComplexUnitsFeature");
        if (this.isComplexUnitsFeatureEnabled) {
            this.listOfKeyActions.push(KeyAction.PendingUnitApprovals);
        }
        /* We only check this here to get information about the associated internal engagements. Otherwise all child components call the state themselves. */
        const engagementDetails$ = this.store.select(
            getEntireEngagementDetails(selectedId)
        );
        engagementDetails$
            .pipe(untilDestroyed(this))
            .subscribe((engagementDetails: IEngagementDetailsState) => {
                /* Listen for invalidation that indicates an internal refresh. This will refresh the data and manage telemetry if the event is detected.
                You no longer need to listen for the refresh event off of FXP Broadcast Service*/
                this.refreshOnItemInvalidation(engagementDetails);

                /* set attributes when content successfully loads */
                if (engagementDetails.loaded) {
                    this.engagementDetails = this.engagementDetailService.getEngagementDetailsV2(
                        engagementDetails.engagementDetails
                    );
                }
            });

        const userPreference$ = this.store.select((getEntireUserPreference()));
        userPreference$
            .pipe(untilDestroyed(this))
            .subscribe((userPreferenceState: IUserPreferenceState) => {
                this.currentRouteName = this.stateService.current.name;
                if (userPreferenceState.loaded && this.currentRouteName.toLowerCase() === (RouteName.EngagementSummaryV2.toLowerCase())) {
                    let isSummaryV2FeedbackSubmitted: boolean = false;
                    const feedbackDetails =
                            userPreferenceState.userPreference.feedbackList;
                    if (
                        feedbackDetails &&
                            feedbackDetails.length > 0 &&
                            feedbackDetails.filter(
                                (obj) => obj.key === FeedBackEntity.SummaryV2
                            ).length > 0
                    ) {
                        isSummaryV2FeedbackSubmitted = feedbackDetails.filter(
                            (obj) => obj.key === FeedBackEntity.SummaryV2
                        )[0].value;
                    }
                    if (
                        !isSummaryV2FeedbackSubmitted &&
                            this.sharedFunctionsService.shouldLaunchFeedbackModal(
                                FeedBackEntity.SummaryV2
                            )
                    ) {
                        this.feedbackModalService.openFeedbackModal(
                            InlineSurveyDataList.SummaryV2Survey,
                            FeedBackEntity.SummaryV2,
                            SourceConstants.Component.EngagementSummaryV2Page
                        );
                    }
                }
            });
    }

    /**
     * Lifecycle hook that runs (continuously) after content for the component is checked.
     * Used to dynamically load extra components into the telemetry service to meausre pageload time.
     */
    public ngAfterContentChecked(): void {
        if (
            !this.haveMountedComponents &&
            this.engagementDetails &&
            this.engagementDetails.hasAssociatedEngagements
        ) {
            this.addNewChildComponent(Components.InternalAssociatedEngagements);
            this.haveMountedComponents = true;
        }
    }

    public onShowCopilotSummary(value: boolean): void {
        this.shouldDisplayCopilotSummary = value;
    }
}
